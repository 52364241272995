export const officeEnum = [
  'Erlangen',
  'Bochum',
  'Kram',
  'Tunis',
  'Kelibia',
  'Sousse',
  'Kasserine',
  'Gabes',
  'Monastir',
  'Mahdia',
  'Beja',
  'Kef',
  'Kairouan',
  'Seliana',
  'Morroco',
].sort();
