import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';

export const Menu = () => {
  const navigate = useNavigate();

  return (
    <List component='nav'>
      <ListItemButton onClick={() => navigate('/candidates')}>
        <ListItemIcon>
          <AssignmentIndIcon />
        </ListItemIcon>
        <ListItemText primary='Candidates' />
      </ListItemButton>
    </List>
  );
};
