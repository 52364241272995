import { Navigate } from 'react-router-dom';
import { useGetCurrentUserQuery } from '../auth/slices/usersApiSlice';
import { LoadingBackdrop } from '../shared/components/LoadingBackdrop';

const AuthGuard: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const {
    data: { currentUser } = {},
    isError,
    isLoading,
  } = useGetCurrentUserQuery();

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  if (isError || !currentUser) {
    return <Navigate to='/signin' replace />;
  }

  return children;
};

export default AuthGuard;
