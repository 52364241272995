import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../store';

type PageProps = {
  title: string;
  children: React.ReactNode;
};

export const Page: React.FC<PageProps> = ({ title, children }) => {
  return (
    <Box
      sx={{
        padding: '1.75rem',
      }}
    >
      <Typography variant='h4'>{title}</Typography>

      {children}
    </Box>
  );
};
