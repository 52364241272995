import MuiDrawer from '@mui/material/Drawer';
import { SIDEBAR_WIDTH } from './_constants';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Menu } from './Menu';

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: SIDEBAR_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

type SidebarProps = {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
};

export const Sidebar: React.FC<SidebarProps> = ({
  isDrawerOpen,
  toggleDrawer,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Drawer variant='permanent' open={isDrawerOpen}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <Menu />
    </Drawer>
  );
};
