import { FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';
import { DatePickerInput } from '../inputs/DatePickerInput';
import { isValidDate } from '../../utils/utils';

type DatePickerControlProps = {
  control: any;
  name: string;
  label?: string;
  defaultValue?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
  validate?: (value: string) => string | boolean;
};

export const DatePickerControl: React.FC<DatePickerControlProps> = ({
  control,
  name,
  label,
  defaultValue,
  required,
  size = 'small',
  variant = 'outlined',
  validate,
}) => {
  const rules = {
    ...(required ? { required: `Dieses Feld ist erforderlich` } : {}),
    validate: {
      dateValidation: isValidDate,
      ...validate,
    },
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl>
          <DatePickerInput
            label={label}
            value={value}
            onChange={onChange}
            required={required}
            error={error}
            size={size}
            variant={variant}
          />
        </FormControl>
      )}
    />
  );
};
