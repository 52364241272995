export const deficitNoticeEnum = [
  'Fehlendes Dokument',
  'Leg TN',
  'Beim Übersetzer',
  'Leg DE',
  'Wartende Anzahlung',
  'in Bearbeitung',
  'Verzicht',
  'Teil',
  'Voll',
];
