import axios from 'axios';
import dayjs from 'dayjs';
import { API_BASE_URL } from '../../config';

export const arrayToSelectListItems = (array: string[]) => {
  return array.map((item) => ({
    text: item,
    value: item,
  }));
};

export const isValidDate = (date: any) => {
  const parsedDate = dayjs(date);
  return parsedDate.isValid() || 'Invalid date format';
};

export const formatFileSize = (sizeInBytes: number) => {
  const KB = 1024;
  const MB = KB * KB;

  if (sizeInBytes < MB) {
    return (sizeInBytes / KB).toFixed(2) + ' KB';
  } else {
    return (sizeInBytes / MB).toFixed(2) + ' MB';
  }
};

export const openFile = (url: string, fileName: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const downloadFile = async (
  storageName: string,
  originalName: string
) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/files/download/${encodeURIComponent(
        storageName
      )}/${encodeURIComponent(originalName)}`,
      {
        responseType: 'blob', // Important to specify response type as blob
        withCredentials: true, // Include credentials if needed
      }
    );

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(response.data);

    // Create an anchor element and trigger a download
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = originalName;
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (err) {
    console.error(err);
  }
};
