import { useEffect, useState } from 'react';
import { BsFiletypeDoc, BsFiletypePdf } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { MdRefresh, MdUpload } from 'react-icons/md';
import { IFileInfo } from '../../../shared/models/fileInfo';
import { formatFileSize } from '../../../shared/utils/utils';
import { useUploadFileMutation } from '../../api/apiSlice';

type NotUploadedFileProps = {
  id: string;
  file: File;
  onUploaded: (fileInfo: IFileInfo) => void;
  onRemove: () => void;
};

export default function NotUploadedFile({
  id,
  file,
  onUploaded,
  onRemove,
}: NotUploadedFileProps) {
  const [status, setStatus] = useState<'uploading' | 'error'>('uploading');

  const [uploadFile] = useUploadFileMutation();

  useEffect(() => {
    if (status === 'uploading') {
      uploadFile({
        folderName: 'candidates',
        file,
      })
        .unwrap()
        .then((fileInfo) => {
          onUploaded(fileInfo);
        })
        .catch((err) => {
          console.error(err);
          setStatus('error');
        });
    }
  }, [status]);

  const handleRetry = () => {
    setStatus('uploading');
  };

  return (
    <div className='flex gap-1 border border-blue-300 py-2 px-3 text-xs bg-blue-50'>
      {file.type === 'application/pdf' ? (
        <BsFiletypePdf size={28} />
      ) : (
        <BsFiletypeDoc size={28} />
      )}
      <div className='flex flex-col'>
        <span className='max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap'>
          {file.name}
        </span>
        {status === 'uploading' && (
          <span className='text-[0.68rem]'>{formatFileSize(file.size)}</span>
        )}
        {status === 'error' && (
          <span className='text-[0.68rem] text-red-600'>
            File failed to upload.
          </span>
        )}
      </div>
      {status === 'uploading' && (
        <div className='ml-8 flex items-center gap-1'>
          <MdUpload size={18} />
          <span>Uploading...</span>
        </div>
      )}
      {status === 'error' && (
        <div className='ml-8 flex items-center gap-1'>
          <button onClick={handleRetry}>
            <MdRefresh size={18} />
          </button>
          <button onClick={() => onRemove()}>
            <IoMdClose size={18} />
          </button>
        </div>
      )}
    </div>
  );
}
