import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CustomError } from '../../shared/models/customError';
import { SnackbarModel } from '../../shared/models/snackbar';

type UIState = {
  error: CustomError | null;
  snackbar: SnackbarModel | null;
  isLoading: boolean;
  navigateToUrl: string | null;
};

const initialState: UIState = {
  error: null,
  snackbar: null,
  isLoading: false,
  navigateToUrl: null,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    errorThrown(state, action: PayloadAction<CustomError>) {
      state.error = action.payload;
    },
    errorDismissed(state) {
      state.error = null;
    },
    snackbarShown(state, action: PayloadAction<SnackbarModel>) {
      state.snackbar = action.payload;
    },
    snackbarClosed(state) {
      state.snackbar = null;
    },
    loadingStarted(state) {
      state.isLoading = true;
    },
    loadingFinished(state) {
      state.isLoading = false;
    },
    navigateTo(state, action: PayloadAction<string | null>) {
      state.navigateToUrl = action.payload;
    },
  },
});

export const {
  errorThrown,
  errorDismissed,
  snackbarShown,
  snackbarClosed,
  loadingStarted,
  loadingFinished,
  navigateTo,
} = uiSlice.actions;

export const uiReducer = uiSlice.reducer;
