import CssBaseline from '@mui/material/CssBaseline';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ErrorDialog } from './shared/components/dialogs/ErrorDialog';
import { Modal } from './shared/components/dialogs/Modal';
import { Snackbar } from './shared/components/snackbars/Snackbar';
import { useModal } from './shared/context/ModalContext';
import { useAppDispatch, useAppSelector } from './store';
import { navigateTo } from './store/slices/uiSlice';

const App = () => {
  const { modalContent } = useModal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navigateToUrl = useAppSelector((state) => state.ui.navigateToUrl);

  useEffect(() => {
    if (navigateToUrl) {
      navigate(navigateToUrl, { replace: navigateToUrl === '/not-found' });
      dispatch(navigateTo(null));
    }
  }, [navigateToUrl]);

  return (
    <>
      <CssBaseline />
      <ErrorDialog />
      <Snackbar />
      {modalContent && <Modal />}
      <Outlet />
    </>
  );
};

export default App;
