import { MuiTelInput } from 'mui-tel-input';
import { Controller, FieldError } from 'react-hook-form';

type TelInputProps = {
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
  error?: FieldError;
  multiline?: boolean;
  rows?: number;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
  onChange?: (value: string) => void;
};

export const TelInput: React.FC<TelInputProps> = ({
  name,
  label,
  placeholder,
  value,
  required,
  error,
  size = 'small',
  variant = 'filled',
  onChange,
}) => {
  return (
    <MuiTelInput
      name={name}
      label={required ? `${label} *` : label}
      defaultCountry='TN'
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={error ? error.message : null}
      size={size}
      variant={variant}
    />
  );
};
