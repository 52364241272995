import Divider from '@mui/material/Divider';
import { Page } from '../../../layout/Page';
import { CandidatesFilter } from '../components/CandidatesFilter';
import { CandidatesGrid } from '../components/CandidatesGrid';
import { useEffect, useState } from 'react';
import { useGetAllCandidatesQuery } from '../slices/candidatesApiSlice';
import { ICandidate } from '../../../models/candidate';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { useNavigate } from 'react-router-dom';

export const CandidatesPage = () => {
  const navigate = useNavigate();

  const {
    data: candidates,
    isFetching: isLoadingCandidates,
    error,
  } = useGetAllCandidatesQuery();

  const [filteredCandidates, setFilteredCandidates] = useState<ICandidate[]>(
    candidates || []
  );

  const [filters, setFilters] = useState({
    languageLevel: '',
    area: '',
    office: '',
    deficitNotice: '',
  });

  const updateFilter = (filterName: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  useEffect(() => {
    const filteredData =
      candidates?.filter(
        (candidate) =>
          (filters.languageLevel === '' ||
            candidate.languageLevel === filters.languageLevel) &&
          (filters.area === '' || candidate.area === filters.area) &&
          (filters.office === '' || candidate.office === filters.office) &&
          (filters.deficitNotice === '' ||
            candidate.deficitNotice === filters.deficitNotice)
      ) || [];
    setFilteredCandidates(filteredData);
  }, [candidates, filters]);

  const handleExport = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(filteredCandidates);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'Candidates List' + fileExtension);
  };

  return (
    <Page title='Candidates'>
      <CandidatesFilter filters={filters} updateFilter={updateFilter} />
      <Divider
        sx={{
          margin: '1rem 0',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 1,
          marginBottom: '1rem',
        }}
      >
        <Button
          variant='contained'
          color='primary'
          startIcon={<AddIcon />}
          onClick={() => navigate('/candidates/new')}
        >
          Add new candidate
        </Button>
        <Button
          variant='contained'
          color='secondary'
          startIcon={<GetAppIcon />}
          onClick={handleExport}
        >
          Export
        </Button>
      </Box>
      <CandidatesGrid
        candidates={filteredCandidates}
        isLoading={isLoadingCandidates}
      />
    </Page>
  );
};
