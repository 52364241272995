export const languageLevelEnum = [
  'A0',
  'A1',
  'A1-Z',
  'A2',
  'A2-Z',
  'B1.1',
  'B1.2',
  'B1-T',
  'B1-Z',
  'B2.1',
  'B2.2',
  'B2-T',
  'B2-Z',
];
