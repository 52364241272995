import { Controller } from 'react-hook-form';
import { TextInput } from '../inputs/TextInput';

type TextControlProps = {
  control?: any;
  type?: 'text' | 'password' | 'email' | 'number';
  name: string;
  label?: string;
  defaultValue?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
  validate?: (value: string) => string | boolean;
};

export const TextControl: React.FC<TextControlProps> = ({
  control,
  type = 'text',
  name,
  label,
  defaultValue = '',
  required,
  multiline,
  rows,
  size = 'small',
  variant = 'outlined',
  validate,
}) => {
  const rules = {
    ...(required ? { required: `Dieses Feld ist erforderlich` } : {}),
    ...(validate ? { validate } : {}),
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextInput
            type={type}
            name={name}
            label={required ? `${label} *` : label}
            value={value}
            onChange={onChange}
            error={error}
            multiline={multiline}
            rows={rows}
            size={size}
            variant={variant}
          />
        );
      }}
    />
  );
};
