import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { VisuallyHiddenInput } from '../../../shared/components/inputs/VisuallyHiddenInput';
import { openFile } from '../../../shared/utils/utils';
import { apiSlice, useUploadFileMutation } from '../../api/apiSlice';
export const CandidatePhoto = () => {
  const { watch, setValue, getValues } = useFormContext();

  const photoInfo = watch('photo');
  const [photo, setPhoto] = useState<File | null>(null);

  const initialPhotoURL = watch('photoUrl');
  const [photoURL, setPhotoURL] = useState<string | null>(null);

  useEffect(() => {
    setPhotoURL(initialPhotoURL);
  }, [initialPhotoURL]);

  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files[0]) {
      setPhoto(files[0]);
      setPhotoURL(URL.createObjectURL(files[0]));
    }
  };

  const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();

  const handleUpload = async () => {
    if (photo) {
      const fileInfo = await uploadFile({
        folderName: 'candidates',
        file: photo,
      }).unwrap();

      setValue('photo', fileInfo);
    }
  };

  const handleDeselectPhoto = () => {
    setPhoto(null);
    setPhotoURL(null);
    setValue('photo', null);
  };

  const [triggerGetPersistedUrl, { isFetching: isDownloading }] =
    apiSlice.endpoints.getPresignedUrl.useLazyQuery();

  const handleDownload = async () => {
    const { data: fileUrl } = await triggerGetPersistedUrl({
      storageName: photoInfo?.storageName,
      originalName: photoInfo?.originalName,
    });

    if (fileUrl) {
      openFile(fileUrl, photoInfo.originalName);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        maxWidth: 200,
      }}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {photoURL && (
          <IconButton
            aria-label='delete'
            color='error'
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1,
            }}
            onClick={() => handleDeselectPhoto()}
          >
            <DeleteIcon
              sx={{
                fontSize: '1.2rem',
              }}
            />
          </IconButton>
        )}
        <Avatar
          alt='Remy Sharp'
          src={photoURL || undefined}
          sx={{ width: 112, height: 112, margin: '16px auto 0 auto' }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: '100%',
        }}
      >
        {!photoInfo && (
          <Button variant='contained' component='label' fullWidth>
            Browse files
            <VisuallyHiddenInput
              type='file'
              accept='image/*'
              onChange={handlePhotoChange}
            />
          </Button>
        )}
        {!photoInfo && (
          <LoadingButton
            variant='contained'
            loading={isUploading}
            loadingPosition='start'
            startIcon={<CloudUploadIcon />}
            disabled={!photo}
            onClick={() => handleUpload()}
            fullWidth
          >
            Upload file
          </LoadingButton>
        )}
        {photoInfo && (
          <LoadingButton
            variant='contained'
            loadingPosition='start'
            startIcon={<CloudDownloadIcon />}
            loading={isDownloading}
            onClick={handleDownload}
            fullWidth
          >
            Download file
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};
