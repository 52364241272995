import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IoMdDownload } from 'react-icons/io';
import { VisuallyHiddenInput } from '../../../shared/components/inputs/VisuallyHiddenInput';
import { IFileInfo } from '../../../shared/models/fileInfo';
import { downloadFile } from '../../../shared/utils/utils';
import { candidateFiles } from '../constants/candidate-files';
import NotUploadedFile from './NotUploadedFile';
import UploadedFile from './UploadedFile';

export const CandidatesDocuments = () => {
  const [filesForm, setFilesForm] = useState<
    {
      id: string;
      label: string;
      files: File[] | null;
    }[]
  >(candidateFiles);
  const { setValue, watch } = useFormContext();
  const [itemsSelected, setItemsSelected] = useState<string[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, files: fileList } = event.target;

    if (fileList && fileList.length > 0) {
      const formItem = filesForm.find((obj) => obj.id === id);

      if (formItem) {
        formItem.files = Array.from(fileList);
        setFilesForm([...filesForm]);
      }
    }
  };

  const handleDeselectFile = (id: string, name: string, isNew: boolean) => {
    if (isNew) {
      setFilesForm((prevFiles) =>
        prevFiles.map((file) => {
          if (file.id === id) {
            file.files = file.files!.filter((f) => f.name !== name);
          }

          return file;
        })
      );
    } else {
      const files = watch(id) as IFileInfo[];
      const updatedFiles = files.filter((file) => file.storageName !== name);

      setValue(id, updatedFiles, { shouldDirty: true });
    }
  };

  const handleUploadedFile = (id: string, fileInfo: IFileInfo) => {
    const files = watch(id) || [];
    const updatedFiles = [...files, fileInfo];

    setValue(id, updatedFiles, { shouldDirty: true });
    setFilesForm((prevFiles) =>
      prevFiles.map((f) => {
        if (f.id === id) {
          f.files = f.files!.filter(
            (file) => file.name !== fileInfo.originalName
          );
        }

        return f;
      })
    );
  };

  const handleItemSelect = (id: string) => {
    if (itemsSelected.includes(id)) {
      setItemsSelected((prevItems) => prevItems.filter((item) => item !== id));
    } else {
      setItemsSelected((prevItems) => [...prevItems, id]);
    }
  };

  const handleSelectAll = () => {
    if (itemsSelected.length === filesForm.length || itemsSelected.length > 0) {
      setItemsSelected([]);
    } else {
      setItemsSelected(filesForm.map((item) => item.id));
    }
  };

  const handleDownloadMulitple = async () => {
    itemsSelected.forEach(async (id) => {
      const files = (watch(id) || []) as IFileInfo[];

      for (const file of files) {
        downloadFile(file.storageName, file.originalName);
      }
    });
  };

  return (
    <Card elevation={0}>
      <CardHeader title='Dokumente' />
      <CardContent>
        <table className='border-collapse text-sm'>
          <tbody>
            <tr className='bg-gray-50  text-gray-700'>
              <td className='border border-gray-300' colSpan={4}>
                <div className='flex items-center'>
                  <Checkbox
                    checked={itemsSelected.length !== 0}
                    onChange={handleSelectAll}
                  />
                  <span>
                    {itemsSelected.length}{' '}
                    {`item${itemsSelected.length > 0 ? 's' : ''} selected`}
                  </span>
                  {itemsSelected.length > 0 && (
                    <button
                      className='border border-gray-300 ml-2 flex gap-2 py-1 px-2'
                      onClick={handleDownloadMulitple}
                    >
                      <IoMdDownload size={18} />
                      <span>Download</span>
                    </button>
                  )}
                </div>
              </td>
            </tr>
            {filesForm.map((formItem) => {
              const id = formItem.id as string;
              const fileInfos = watch(id) as IFileInfo[];

              return (
                <tr key={id} className='bg-gray-50  text-gray-700'>
                  <td className='border border-gray-300 align-top'>
                    <Checkbox
                      onChange={() => handleItemSelect(id)}
                      checked={itemsSelected.includes(id)}
                    />
                  </td>
                  <td className='py-3 px-4 border border-gray-300 align-top'>
                    {formItem.label}
                  </td>
                  <td className='py-3 px-4 border border-gray-300 align-top'>
                    <Button
                      component='label'
                      variant='contained'
                      sx={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                    >
                      Browse Files
                      <VisuallyHiddenInput
                        id={id}
                        type='file'
                        multiple
                        onChange={handleFileChange}
                        accept='application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                      />
                    </Button>
                  </td>
                  <td className='py-3 px-4 border border-gray-300'>
                    <div className='flex flex-col items-start gap-4'>
                      {fileInfos && fileInfos.length > 0 && (
                        <ul className='flex flex-col items-start gap-4'>
                          {fileInfos?.map((fileInfo) => {
                            return (
                              <li key={fileInfo.storageName}>
                                <UploadedFile
                                  id={id}
                                  storageName={fileInfo.storageName}
                                  originalName={fileInfo.originalName}
                                  file={
                                    {
                                      name: fileInfo.originalName,
                                      size: fileInfo.size,
                                      type: fileInfo.originalName.includes(
                                        '.pdf'
                                      )
                                        ? 'application/pdf'
                                        : 'application/msword',
                                    } as File
                                  }
                                  onRemove={() =>
                                    handleDeselectFile(
                                      id,
                                      fileInfo.storageName,
                                      false
                                    )
                                  }
                                />
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {formItem.files && formItem.files.length > 0 && (
                        <ul className='flex flex-col items-start gap-4'>
                          {formItem.files?.map((file) => {
                            return (
                              <li key={file.name}>
                                <NotUploadedFile
                                  id={id}
                                  file={file}
                                  onRemove={() =>
                                    handleDeselectFile(id, file.name, true)
                                  }
                                  onUploaded={(fileInfo) =>
                                    handleUploadedFile(id, fileInfo)
                                  }
                                />
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>

                    {(!formItem.files || formItem.files.length === 0) &&
                      (!fileInfos || fileInfos.length === 0) && (
                        <p className='text-gray-500'>No files selected</p>
                      )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
};
