import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant='h1'>404</Typography>
      <Typography variant='h3'>Not found</Typography>
      <Typography variant='body1'>
        The resource equested could not be found on this server!
      </Typography>
    </Box>
  );
};
