export const candidateFiles = [
  {
    id: 'cv',
    label: 'Lebenslauf',
    files: null,
  },
  {
    id: 'passport',
    label: 'Reisepass',
    files: null,
  },
  {
    id: 'birthCertificate',
    label: 'Geburtsurkunde',
    files: null,
  },
  {
    id: 'marriageCertificate',
    label: 'Heiratsurkunde',
    files: null,
  },
  {
    id: 'diploma',
    label: 'Diplom',
    files: null,
  },
  {
    id: 'theoreticalSubjects',
    label: 'Theoretische Unterrichtsfächer',
    files: null,
  },
  {
    id: 'mandatoryInternship',
    label: 'Pflicht Praktika',
    files: null,
  },
  {
    id: 'professionalLicense',
    label: 'Berufserlaubnis',
    files: null,
  },
  {
    id: 'gradesOverview',
    label: 'Notenübersicht',
    files: null,
  },
  {
    id: 'equivalence',
    label: 'Äquivalenz',
    files: null,
  },
  {
    id: 'workCertificate',
    label: 'Arbeitsbescheinigung',
    files: null,
  },
  {
    id: 'tasksSheet',
    label: 'Aufgabenblatt',
    files: null,
  },
  {
    id: 'authorizationRecognition',
    label: 'Vollmacht Anerkennung',
    files: null,
  },
  {
    id: 'applicationRecognition',
    label: 'Antrag Anerkennung',
    files: null,
  },
  {
    id: 'kue',
    label: 'KÜE',
    files: null,
  },
  {
    id: 'languageCertificate',
    label: 'Sprach Zertifikat',
    files: null,
  },
  {
    id: 'deficitNoticeFiles',
    label: 'Defizitbescheid',
    files: null,
  },
  {
    id: 'gmgContracts',
    label: 'Verträge GMG',
    files: null,
  },
  {
    id: 'employerContracts',
    label: 'Verträge AG',
    files: null,
  },
  {
    id: 'schoolContracts',
    label: 'Verträge Schule',
    files: null,
  },
  {
    id: 'powerOfAttorney81a',
    label: 'Vollmacht 81a',
    files: null,
  },
  {
    id: 'subPowerOfAttorney',
    label: 'Untervollmacht',
    files: null,
  },
  {
    id: 'declaration',
    label: 'Erklärung',
    files: null,
  },
  {
    id: 'trainingPlan',
    label: 'Weiterbildung Plan',
    files: null,
  },
  {
    id: 'additionalSheetA',
    label: 'Zusatzblatt A',
    files: null,
  },
  {
    id: 'visa',
    label: 'Visum',
    files: null,
  },
  {
    id: 'insurance',
    label: 'Versicherung',
    files: null,
  },
  {
    id: 'bank',
    label: 'Bank',
    files: null,
  },
  {
    id: 'bgForm',
    label: 'BG Formular',
    files: null,
  },
  {
    id: 'personalForm',
    label: 'Personalbogen',
    files: null,
  },
  {
    id: 'vaccinationCertificate',
    label: 'Impfausweis',
    files: null,
  },
  {
    id: 'b3',
    label: 'B3',
    files: null,
  },
];
