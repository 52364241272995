import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import countryList from 'country-list';
import { useCallback, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  BlockerFunction,
  useBlocker,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Page } from '../../../layout/Page';
import { ICandidate } from '../../../models/candidate';
import { LoadingBackdrop } from '../../../shared/components/LoadingBackdrop';
import { DatePickerControl } from '../../../shared/components/controls/DatePickerControl';
import { DropdownControl } from '../../../shared/components/controls/DropdownControl';
import { TelControl } from '../../../shared/components/controls/TelControl';
import { TextControl } from '../../../shared/components/controls/TextControl';
import { AlertModal } from '../../../shared/components/dialogs/AlertModal';
import { useModal } from '../../../shared/context/ModalContext';
import { areaEnum } from '../../../shared/enums/area';
import { deficitNoticeEnum } from '../../../shared/enums/deficitNotice';
import { languageLevelEnum } from '../../../shared/enums/languageLevel';
import { officeEnum } from '../../../shared/enums/office';
import { arrayToSelectListItems } from '../../../shared/utils/utils';
import { CandidatePhoto } from '../components/CandidatePhoto';
import { CandidatesDocuments } from '../components/CandidatesDocuments';
import { candidateFiles } from '../constants/candidate-files';
import {
  useAddCandidateMutation,
  useEditCandidateMutation,
  useGetCandidateByIdQuery,
} from '../slices/candidatesApiSlice';

export const ManageCandidatePage = () => {
  const navigate = useNavigate();

  const { openModal, closeModal } = useModal();
  const { candidateId } = useParams<{ candidateId: string }>();

  const isNewCandidate = !candidateId;

  const { data: candidate, isLoading: isCandidateLoading } =
    useGetCandidateByIdQuery(candidateId || '');

  const methods = useForm<ICandidate>();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;
  const {
    fields: experiences,
    append: appendExperience,
    remove: removeExperience,
  } = useFieldArray({ control, name: 'experiences' });

  const {
    fields: interviews,
    append: appendInterview,
    remove: removeInterview,
  } = useFieldArray({ control, name: 'interviews' as any });

  const shouldBlock = useCallback<BlockerFunction>(
    () => isDirty && !isSubmitSuccessful,
    [isDirty, isSubmitSuccessful]
  );
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    console.log('blocker.state in first use effect', blocker.state);
    if (blocker.state === 'blocked') {
      openModal(
        <AlertModal
          title='Warning!'
          message='You have unsaved changes. Are you sure you want to leave?'
          onSubmit={() => {
            blocker.proceed();
            closeModal();
          }}
          onCancel={() => {
            blocker.reset();
            closeModal();
          }}
        />
      );
    }
  }, [blocker.state]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isDirty) {
        const message =
          'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    if (candidate) {
      Object.keys(candidate).forEach((key) => {
        setValue(key as keyof ICandidate, candidate[key as keyof ICandidate]);
      });
    }
  }, [candidate]);

  const [addCandidate, { isLoading: isAdding, isSuccess: isAddingSuccess }] =
    useAddCandidateMutation();
  const [editCandidate, { isLoading: isEditing, isSuccess: isEditingSuccess }] =
    useEditCandidateMutation();

  useEffect(() => {
    if (isAddingSuccess || isEditingSuccess) {
      navigate('/candidates');
    }
  }, [isAddingSuccess, isEditingSuccess]);

  const onSubmit = async (data: any) => {
    const filePropNames = candidateFiles.map((file) => file.id);

    const emptyFiles = filePropNames.filter((propName) => {
      return !data[propName] || data[propName].length === 0;
    });

    const emptyFilesNames = emptyFiles.map(
      (propName) =>
        candidateFiles.find((file) => file.id === propName)?.label || ''
    );

    if (emptyFiles.length > 0) {
      openModal(
        <AlertModal
          title='Warnung!'
          message='Es wurden nicht alle Dateien hochgeladen'
          infos={emptyFilesNames}
          onSubmit={async () => {
            closeModal();

            if (isNewCandidate) {
              await addCandidate(data);
            } else {
              await editCandidate(data);
            }
          }}
          onCancel={closeModal}
        />
      );
    } else {
      if (isNewCandidate) {
        await addCandidate(data);
      } else {
        await editCandidate(data);
      }
    }
  };

  if (isCandidateLoading) {
    return <LoadingBackdrop />;
  }

  return (
    <Page title={isNewCandidate ? 'Create New Candidate' : 'Edit Candidate'}>
      <FormProvider {...methods}>
        <Card elevation={0}>
          <CardHeader title='Persönliche Informationen' />
          <CardContent>
            <Grid container spacing={2} sx={{ width: '100%' }}>
              <Grid item xs={6}>
                <FormGroup>
                  <DropdownControl
                    control={control}
                    name='office'
                    label='Büro'
                    options={arrayToSelectListItems(officeEnum)}
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <DropdownControl
                    control={control}
                    name='area'
                    label='Bereich'
                    options={arrayToSelectListItems(areaEnum)}
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <DropdownControl
                    control={control}
                    name='destination'
                    label='Zielort'
                    options={arrayToSelectListItems(
                      countryList.getNames().sort()
                    )}
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <DropdownControl
                    control={control}
                    name='gender'
                    label='Geschlecht'
                    options={[
                      { text: 'Herr', value: 'male' },
                      { text: 'Frau', value: 'female' },
                    ]}
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TextControl
                    control={control}
                    name='firstName'
                    label='Vorname'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TextControl
                    control={control}
                    name='lastName'
                    label='Nachname'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <DatePickerControl
                    control={control}
                    name='registrationDate'
                    label='Anmeldungsdatum'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <DatePickerControl
                    control={control}
                    name='birthDate'
                    label='Geburtsdatum'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TextControl
                    control={control}
                    name='birthPlace'
                    label='Geburtsort'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TelControl
                    control={control}
                    name='mobileNumber'
                    label='Handynummer'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TelControl
                    control={control}
                    name='whatsappNumber'
                    label='Whatsapp Nummer'
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TextControl
                    control={control}
                    name='address.street'
                    label='Straße'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TextControl
                    control={control}
                    name='address.city'
                    label='Stadt'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TextControl
                    control={control}
                    name='address.state'
                    label='Bundesland'
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TextControl
                    control={control}
                    name='address.zip'
                    label='PLZ'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TextControl
                    control={control}
                    name='address.country'
                    label='Land'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <TextControl
                    control={control}
                    type='email'
                    name='email'
                    label='Email'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TextControl
                    control={control}
                    name='passportNumber'
                    label='Reisepassnummer'
                    required
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <DropdownControl
                    control={control}
                    name='languageLevel'
                    label='Sprachniveau'
                    options={arrayToSelectListItems(languageLevelEnum)}
                    required
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Divider />

        <Card elevation={0}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pr: 2,
            }}
          >
            <CardHeader title='Erfahrungen' />
            <Button
              variant='outlined'
              onClick={() =>
                appendExperience({
                  department: '',
                  duration: 0,
                })
              }
            >
              Hinzüfugen
            </Button>
          </Box>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              {experiences.length === 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant='body2' color='text.secondary'>
                    Keine Erfahrungen vorhanden
                  </Typography>
                </Box>
              )}
              {experiences.map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                  }}
                >
                  <Grid container spacing={2} sx={{ width: '100%' }}>
                    <Grid item xs={6}>
                      <FormGroup>
                        <TextControl
                          control={control}
                          name={`experiences.${index}.department`}
                          label='Abteilung'
                          required
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <FormGroup>
                        <TextControl
                          type='number'
                          control={control}
                          name={`experiences.${index}.duration`}
                          label='Dauer'
                          required
                          validate={(value) =>
                            parseInt(value) > 0 || 'Dauer muss > 0 sein'
                          }
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Button
                    color='error'
                    variant='outlined'
                    onClick={() => removeExperience(index)}
                  >
                    Löschen
                  </Button>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>

        <Divider />

        <Card elevation={0}>
          <CardContent>
            <Grid container spacing={2} sx={{ width: '100%' }}>
              <Grid item xs={6}>
                <FormGroup>
                  <DropdownControl
                    control={control}
                    name='deficitNotice'
                    label='Defizitbescheid'
                    options={arrayToSelectListItems(deficitNoticeEnum)}
                    required
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Divider />

        <Card elevation={0}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pr: 2,
            }}
          >
            <CardHeader title='Vorstellungsgespräche' />
            <Button variant='outlined' onClick={() => appendInterview('')}>
              Hinzüfugen
            </Button>
          </Box>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              {interviews.length === 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant='body2' color='text.secondary'>
                    Keine Vorstellungsgespräche vorhanden
                  </Typography>
                </Box>
              )}
              {interviews.map((field, index) => (
                <Box
                  key={field.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'start',
                    gap: 2,
                  }}
                >
                  <Grid container spacing={2} sx={{ width: '100%' }}>
                    <Grid item xs={12}>
                      <FormGroup>
                        <TextControl
                          control={control}
                          name={`interviews.${index}`}
                          label={`Vorstellungsgespräch ${index + 1}`}
                          required
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Button
                    color='error'
                    variant='outlined'
                    onClick={() => removeInterview(index)}
                  >
                    Löschen
                  </Button>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>

        <Divider />

        <Card elevation={0}>
          <CardContent>
            <Grid container spacing={2} sx={{ width: '100%' }}>
              <Grid item xs={6}>
                <FormGroup>
                  <TextControl
                    control={control}
                    name='employer'
                    label='Arbeitgeber'
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <DatePickerControl
                    control={control}
                    name='contractSignatureDate'
                    label='Vertragsunterzeichnungsdatum'
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <DatePickerControl
                    control={control}
                    name='visaDate'
                    label='Visumdatum'
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <DatePickerControl
                    control={control}
                    name='flightDate'
                    label='Flugdatum'
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <TextControl
                    control={control}
                    name='comment'
                    label='Kommentar'
                    multiline
                    rows={4}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Divider />

        <Card elevation={0}>
          <CardHeader title='Bild des Kandidaten' />
          <CardContent>
            <CandidatePhoto />
          </CardContent>
        </Card>

        <Divider />

        <CandidatesDocuments />

        <Divider />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: 2,
            py: 1,
            px: 3,
          }}
        >
          <LoadingButton
            type='submit'
            loading={isAdding || isEditing}
            loadingPosition='start'
            startIcon={<SaveIcon />}
            variant='contained'
            onClick={handleSubmit(onSubmit)}
          >
            Speichern
          </LoadingButton>
          <Button
            color='inherit'
            variant='outlined'
            onClick={() => navigate('/candidates')}
          >
            Zurück
          </Button>
        </Box>
      </FormProvider>
    </Page>
  );
};
