import { IUser } from '../../models/user';
import { apiSlice } from '../../features/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<{ currentUser: IUser }, void>({
      query: () => ({
        url: 'users/currentUser',
      }),
      providesTags: ['CurrentUser'],
    }),
    signin: builder.mutation<IUser, { username: string; password: string }>({
      query: (credentials) => ({
        url: '/users/signin',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['CurrentUser'],
    }),
    signout: builder.mutation<void, void>({
      query: () => ({
        url: '/users/signout',
        method: 'POST',
      }),
      invalidatesTags: ['CurrentUser'],
    }),
  }),
});

export const { useGetCurrentUserQuery, useSigninMutation, useSignoutMutation } =
  authApiSlice;
