import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';

type AlertModalProps = {
  title: string;
  message: string;
  infos?: string[];
  onSubmit: () => void;
  onCancel: () => void;
};

export const AlertModal: React.FC<AlertModalProps> = ({
  title,
  message,
  infos,
  onSubmit,
  onCancel,
}) => {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {infos && (
          <ul className='ml-4 list-disc mt-2'>
            {infos.map((info, index) => (
              <li key={index}>{info}</li>
            ))}
          </ul>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onSubmit}>Submit</Button>
      </DialogActions>
    </>
  );
};
