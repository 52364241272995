import { Controller } from 'react-hook-form';
import { TelInput } from '../inputs/TelInput';

type TelControlProps = {
  control?: any;
  name: string;
  label?: string;
  defaultValue?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
  validate?: (value: string) => string | boolean;
};

export const TelControl: React.FC<TelControlProps> = ({
  control,
  name,
  label,
  defaultValue = '',
  required,
  size = 'small',
  variant = 'outlined',
  validate,
}) => {
  const rules = {
    ...(required ? { required: `Dieses Feld ist erforderlich` } : {}),
    ...(validate ? { validate } : {}),
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TelInput
          name={name}
          label={required ? `${label} *` : label}
          value={value}
          onChange={onChange}
          error={error}
          size={size}
          variant={variant}
        />
      )}
    />
  );
};
