import { ICandidate } from '../../../models/candidate';
import { apiSlice } from '../../api/apiSlice';

export const candidatesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCandidates: builder.query<ICandidate[], void>({
      query: () => '/candidates',
      providesTags: () => ['Candidates'],
    }),
    getCandidateById: builder.query<ICandidate, string>({
      query: (candidateId) => `/candidates/${candidateId}`,
      providesTags: (result, error, args) => [
        'Candidates',
        { type: 'Candidate', id: args },
      ],
    }),
    addCandidate: builder.mutation<ICandidate, ICandidate>({
      query: (candidate) => ({
        url: `/candidates`,
        method: 'POST',
        body: candidate,
      }),
      invalidatesTags: () => ['Candidates'],
    }),
    editCandidate: builder.mutation<ICandidate, ICandidate>({
      query: (editedCandidate) => ({
        url: `/candidates/${editedCandidate.id}`,
        method: 'PUT',
        body: editedCandidate,
      }),
      invalidatesTags: (result, error, args) => [
        'Candidates',
        { type: 'Candidate', id: args.id },
      ],
    }),
    deleteCandidate: builder.mutation<void, string>({
      query: (candidateId) => ({
        url: `/candidates/${candidateId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Candidates'],
    }),
  }),
});

export const {
  useGetAllCandidatesQuery,
  useGetCandidateByIdQuery,
  useAddCandidateMutation,
  useEditCandidateMutation,
  useDeleteCandidateMutation,
} = candidatesApiSlice;
