import { BsFiletypeDoc, BsFiletypePdf } from 'react-icons/bs';
import { IoMdClose, IoMdDownload } from 'react-icons/io';
import { downloadFile } from '../../../shared/utils/utils';

type UploadedFileProps = {
  id: string;
  storageName: string;
  originalName: string;
  file: File;
  onRemove: () => void;
};

export default function UploadedFile({
  file,
  storageName,
  originalName,
  onRemove,
}: UploadedFileProps) {
  const handleDownload = async () => {
    downloadFile(storageName, originalName);
  };

  return (
    <div className='flex gap-1 border border-blue-300 py-2 px-3 text-xs bg-blue-50'>
      {file.type === 'application/pdf' ? (
        <BsFiletypePdf size={28} />
      ) : (
        <BsFiletypeDoc size={28} />
      )}
      <div className='flex flex-col'>
        <span className='max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap'>
          {file.name}
        </span>
        <span className='text-[0.68rem] text-green-600'>
          File successfully uploaded.
        </span>
      </div>
      <div className='ml-8 flex items-center gap-1'>
        <button onClick={handleDownload}>
          <IoMdDownload size={18} />
        </button>
        <button onClick={() => onRemove()}>
          <IoMdClose size={18} />
        </button>
      </div>
    </div>
  );
}
