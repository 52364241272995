import {
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { errorThrown, navigateTo } from '../slices/uiSlice';
import { store } from '..';

export const errorHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.payload.status === 500) {
        store.dispatch(
          errorThrown({
            title: 'Internal Server Error',
            message: action.payload.data.error.message,
          })
        );
      } else if (action.payload.status === 400) {
        store.dispatch(
          errorThrown({
            title: 'Bad Request Error',
            message: action.payload.data.error.message,
          })
        );
      } else if (action.payload.status === 403) {
        store.dispatch(
          errorThrown({
            title: action.payload.data.error.message,
            message: action.payload.data.error.field.message,
          })
        );
      } else if (action.payload.status === 404) {
        store.dispatch(navigateTo('/not-found'));
      } else {
        store.dispatch(
          errorThrown({
            title: 'Unkown Error',
            message: 'An unkown error occured',
          })
        );
      }
    }

    return next(action);
  };
