import Alert from '@mui/material/Alert';
import MuiSnackbar from '@mui/material/Snackbar';
import { useAppDispatch, useAppSelector } from '../../../store';
import { snackbarClosed } from '../../../store/slices/uiSlice';
import { useEffect, useState } from 'react';

export const Snackbar = () => {
  const dispatch = useAppDispatch();
  const snackbar = useAppSelector((state) => state.ui.snackbar);

  const [lastSeverity, setLastSeverity] = useState(snackbar?.severity);
  const [lastMessage, setLastMessage] = useState(snackbar?.message);

  useEffect(() => {
    if (snackbar) {
      setLastSeverity(snackbar.severity);
      setLastMessage(snackbar.message);
    }
  }, [snackbar]);

  const handleClose = () => {
    dispatch(snackbarClosed());
  };

  return (
    <MuiSnackbar
      open={snackbar !== null}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={() => handleClose()}
    >
      <Alert
        onClose={handleClose}
        severity={lastSeverity}
        elevation={6}
        variant='filled'
      >
        {lastMessage}
      </Alert>
    </MuiSnackbar>
  );
};
