import { Controller } from 'react-hook-form';
import { DropdownInput } from '../inputs/DropdownInput';
import { SelectItem } from '../../models/selectListItem';
import FormHelperText from '@mui/material/FormHelperText';

type DropdownControlProps = {
  control: any;
  name: string;
  options: SelectItem[];
  defaultValue?: string;
  label?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
  fullWidth?: boolean;
  validate?: (value: string) => string | boolean;
};

export const DropdownControl: React.FC<DropdownControlProps> = ({
  control,
  name,
  options,
  defaultValue,
  label,
  required,
  size = 'small',
  variant = 'outlined',
  fullWidth = true,
  validate,
}) => {
  const rules = {
    ...(required ? { required: `Dieses Feld ist erforderlich` } : {}),
    ...(validate ? { validate } : {}),
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <DropdownInput
            name={name}
            label={required ? `${label} *` : label}
            options={options}
            value={value}
            onChange={onChange}
            error={!!error}
            size={size}
            variant={variant}
            fullWidth={fullWidth}
          />
          {error ? (
            <FormHelperText
              error
              sx={{
                ml: '14px',
              }}
            >
              {error.message}
            </FormHelperText>
          ) : null}
        </>
      )}
    />
  );
};
