import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DropdownInput } from '../../../shared/components/inputs/DropdownInput';
import { arrayToSelectListItems } from '../../../shared/utils/utils';
import { areaEnum } from '../../../shared/enums/area';
import { officeEnum } from '../../../shared/enums/office';
import { deficitNoticeEnum } from '../../../shared/enums/deficitNotice';
import { languageLevelEnum } from '../../../shared/enums/languageLevel';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import Button from '@mui/material/Button';

type Filters = {
  languageLevel: string;
  area: string;
  office: string;
  deficitNotice: string;
};

type CandidatesFilterProps = {
  filters: Filters;
  updateFilter: (filterName: keyof Filters, value: string) => void;
};

export const CandidatesFilter: React.FC<CandidatesFilterProps> = ({
  filters,
  updateFilter,
}) => {
  const handleClearFilter = () => {
    updateFilter('languageLevel', '');
    updateFilter('area', '');
    updateFilter('office', '');
    updateFilter('deficitNotice', '');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Typography>Bereich</Typography>
          <DropdownInput
            sx={{ minWidth: 200 }}
            name='area'
            options={arrayToSelectListItems(areaEnum)}
            variant='outlined'
            size='small'
            defaultValue=''
            displayEmpty
            emptyText='Alle'
            fullWidth
            value={filters.area}
            onChange={(e) => updateFilter('area', e.target.value)}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Typography>Büro</Typography>
          <DropdownInput
            sx={{ minWidth: 200 }}
            name='area'
            options={arrayToSelectListItems(officeEnum)}
            variant='outlined'
            size='small'
            defaultValue=''
            displayEmpty
            emptyText='Alle'
            value={filters.office}
            onChange={(e) => updateFilter('office', e.target.value)}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Typography>Defizitbescheid</Typography>
          <DropdownInput
            sx={{ minWidth: 200 }}
            name='deficitNotice'
            options={arrayToSelectListItems(deficitNoticeEnum)}
            variant='outlined'
            size='small'
            defaultValue=''
            displayEmpty
            emptyText='Alle'
            fullWidth
            value={filters.deficitNotice}
            onChange={(e) => updateFilter('deficitNotice', e.target.value)}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Typography>Sprachniveau</Typography>
          <DropdownInput
            sx={{ minWidth: 200 }}
            name='languageLevel'
            options={arrayToSelectListItems(languageLevelEnum)}
            variant='outlined'
            size='small'
            defaultValue=''
            displayEmpty
            emptyText='Alle'
            fullWidth
            value={filters.languageLevel}
            onChange={(e) => updateFilter('languageLevel', e.target.value)}
          />
        </Box>
      </Box>

      <Button
        variant='outlined'
        startIcon={<BackspaceOutlinedIcon />}
        onClick={handleClearFilter}
      >
        Clear Filter
      </Button>
    </Box>
  );
};
