export const areaEnum = [
  'KP',
  'KD',
  'ATA',
  'OTA',
  'HB',
  'MTRA',
  'MTLA',
  'PHYSIO',
  'AUSB',
  'ING',
  'Hertz',
];
