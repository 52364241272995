import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  useGetCurrentUserQuery,
  useSigninMutation,
} from '../slices/usersApiSlice';
import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingBackdrop } from '../../shared/components/LoadingBackdrop';

function Copyright(props: any) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link color='inherit' href='https://www.gemeinsam-meds-gewinnen.de/'>
        GMG
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const { data: { currentUser } = {}, isLoading: isLoadingCurrentUser } =
    useGetCurrentUserQuery();

  const [signIn, { isLoading: isSigningIn, isSuccess: isSignedIn }] =
    useSigninMutation();

  useEffect(() => {
    if (currentUser || isSignedIn) {
      navigate('/');
    }
  }, [currentUser, isSignedIn]);

  const onSubmit = async (data: any) => {
    await signIn({
      username: data.username,
      password: data.password,
    });
  };

  if (isLoadingCurrentUser) {
    return <LoadingBackdrop />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              marginTop: 1,
            }}
          >
            <Controller
              name='username'
              control={control}
              rules={{ required: 'Username is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  margin='normal'
                  required
                  fullWidth
                  id='username'
                  label='Username'
                  name='username'
                  autoComplete='username'
                  autoFocus
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />

            <Controller
              name='password'
              control={control}
              rules={{ required: 'Password is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />

            <LoadingButton
              type='submit'
              variant='contained'
              loading={isSigningIn}
              loadingPosition='start'
              startIcon={<LoginIcon />}
              sx={{ mt: 3, mb: 2 }}
              fullWidth
            >
              Sign In
            </LoadingButton>
          </form>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
