import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import SignIn from './auth/pages/SignIn';
import { CandidatesPage } from './features/candidates/pages/CandidatesPage';
import { ManageCandidatePage } from './features/candidates/pages/ManageCandidatePage';
import AuthGuard from './guards/AuthGuard';
import DashboardLayout from './layout/DashboardLayout';
import { ModalProvider } from './shared/context/ModalContext';
import { NotFound } from './shared/pages/NotFound';
import { AppStore } from './store';
import theme from './theme';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: <CandidatesPage />,
          },
          {
            path: 'candidates',
            children: [
              {
                index: true,
                element: <CandidatesPage />,
              },
              {
                path: 'new',
                element: <ManageCandidatePage />,
              },
              {
                path: 'edit/:candidateId',
                element: <ManageCandidatePage />,
              },
            ],
          },
        ],
      },
      {
        path: 'signin',
        element: <SignIn />,
      },
      {
        path: 'not-found',
        element: <NotFound />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

type RootProps = {
  store: AppStore;
};

const Root: React.FC<RootProps> = ({ store }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ModalProvider>
            <RouterProvider router={router} />
          </ModalProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default Root;
