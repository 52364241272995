import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SelectItem } from '../../models/selectListItem';

type DropdownInputProps = {
  sx?: any;
  name: string;
  options: SelectItem[];
  label?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  error?: boolean;
  displayEmpty?: boolean;
  emptyText?: string;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
  fullWidth?: boolean;
  onChange?: (event: SelectChangeEvent<string>) => void;
};

export const DropdownInput: React.FC<DropdownInputProps> = ({
  sx,
  name,
  options,
  label,
  defaultValue,
  value = '',
  required,
  error,
  displayEmpty = false,
  emptyText = '',
  size = 'medium',
  variant = 'outlined',
  fullWidth,
  onChange,
}) => {
  return (
    <FormControl size={size} variant={variant} fullWidth={fullWidth}>
      <InputLabel error={error} required={required} variant={variant}>
        {label}
      </InputLabel>
      <Select
        sx={sx}
        name={name}
        label={label}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        error={error}
        size={size}
        displayEmpty={displayEmpty}
      >
        {displayEmpty && (
          <MenuItem value=''>
            <em>{emptyText}</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
