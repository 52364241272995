import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL, NODE_ENV } from '../../config';
import { IFileInfo } from '../../shared/models/fileInfo';

const pause = (duration: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

console.log('NODE_ENV', NODE_ENV);
console.log('API_BASE_URL', API_BASE_URL);

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    credentials: 'include',
    // fetchFn: async (...args) => {
    //   await pause(800);
    //   return fetch(...args);
    // },
  }),
  tagTypes: ['CurrentUser', 'Candidates', 'Candidate'],
  endpoints: (builder) => ({
    uploadFile: builder.mutation<
      IFileInfo,
      { folderName?: string; file: File }
    >({
      query: ({ folderName, file }) => {
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: folderName ? `/files/upload/${folderName}` : `/files/upload`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    getPresignedUrl: builder.query<
      string,
      { storageName: string; originalName: string }
    >({
      query: ({ storageName, originalName }) =>
        `/files/download?storageName=${storageName}&originalName=${originalName}`,
    }),
    deleteFile: builder.mutation<void, string>({
      query: (fileName) => ({
        url: `/files/${fileName}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useUploadFileMutation,
  useGetPresignedUrlQuery,
  useLazyGetPresignedUrlQuery,
  useDeleteFileMutation,
} = apiSlice;
