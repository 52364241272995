import BadgeIcon from '@mui/icons-material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { GridToolbar } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models/colDef';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICandidate } from '../../../models/candidate';
import { CustomNoRowsOverlay } from '../../../shared/components/CustomNoRowsOverlay';
import { AlertDialog } from '../../../shared/components/dialogs/AlertDialog';
import { useDeleteCandidateMutation } from '../slices/candidatesApiSlice';

type CandidatesGridProps = {
  candidates: ICandidate[];
  isLoading: boolean;
};

export const CandidatesGrid: React.FC<CandidatesGridProps> = ({
  candidates,
  isLoading,
}) => {
  const columns: GridColDef[] = [
    {
      field: 'photoUrl',
      headerName: '',
      width: 60,
      renderCell: (cellValues) => {
        return (
          <Avatar
            alt={`${cellValues.row.firstName} ${cellValues.row.lastName}`}
            src={cellValues.row.photoUrl}
          />
        );
      },
    },
    {
      field: 'firstName',
      headerName: 'Vorname',
    },
    {
      field: 'lastName',
      headerName: 'Nachname',
    },
    {
      field: 'registrationDate',
      headerName: 'Anmeldungsdatum',
      valueFormatter: (params) => {
        return dayjs(params.value).format('DD.MM.YYYY');
      },
    },
    {
      field: 'area',
      headerName: 'Bereich',
    },
    {
      field: 'office',
      headerName: 'Büro',
    },
    {
      field: 'birthDate',
      headerName: 'Geburtsdatum',
      valueFormatter: (params) => {
        return dayjs(params.value).format('DD.MM.YYYY');
      },
    },
    {
      field: 'birthPlace',
      headerName: 'Geburtsort',
    },
    {
      field: 'mobileNumber',
      headerName: 'Handynummer',
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'passportNumber',
      headerName: 'Reisepassnummer',
    },
    {
      field: 'deficitNotice',
      headerName: 'Defizitbescheid',
    },
    {
      field: 'languageLevel',
      headerName: 'Sprachniveau',
    },
    {
      field: 'employer',
      headerName: 'Arbeitgeber',
    },
    {
      field: 'Actions',
      width: 160,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              display: 'flex',
              gap: '0.1rem',
            }}
          >
            <IconButton color='default' onClick={(event) => {}}>
              <BadgeIcon
                sx={{
                  fontSize: '1.25rem',
                }}
              />
            </IconButton>
            <IconButton
              color='default'
              onClick={(event) => {
                editHandler(cellValues.row.id);
              }}
            >
              <EditIcon
                sx={{
                  fontSize: '1.25rem',
                }}
              />
            </IconButton>

            <IconButton
              color='default'
              onClick={(event) => {
                deleteHandler(cellValues.row.id);
              }}
            >
              <DeleteIcon
                sx={{
                  fontSize: '1.25rem',
                }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const navigate = useNavigate();

  const editHandler = (candidateId: string) => {
    navigate(`/candidates/edit/${candidateId}`);
  };

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<string | null>(
    null
  );

  const deleteHandler = (candidateId: string) => {
    setSelectedCandidate(candidateId);
    setIsDeleteDialogOpen(true);
  };

  const cancelDeleteHandler = () => {
    setSelectedCandidate(null);
    setIsDeleteDialogOpen(false);
  };

  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [deleteCandidate, { isLoading: isDeleting }] =
    useDeleteCandidateMutation();

  const submitDeleteHandler = () => {
    if (!selectedCandidate) return;

    deleteCandidate(selectedCandidate);
    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowErrorSnackbar(false)}
      >
        <Alert
          onClose={() => setShowErrorSnackbar(false)}
          severity='error'
          elevation={6}
          variant='filled'
        >
          This is a success message!
        </Alert>
      </Snackbar>
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        title='Are you sure?'
        message='Do you really want to delete this candidate? This action cannot be undone.'
        onSubmit={submitDeleteHandler}
        onCancel={cancelDeleteHandler}
      />

      <div
        style={{
          height: candidates.length === 0 ? 300 : 'auto',
          width: '100%',
        }}
      >
        <DataGrid
          rows={candidates || []}
          columns={columns}
          rowSelection={false}
          disableColumnMenu={true}
          disableDensitySelector
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 20]}
          loading={isLoading || isDeleting}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </div>
    </>
  );
};
