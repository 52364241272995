import TextField from '@mui/material/TextField';
import { FieldError } from 'react-hook-form';

type TextInputProps = {
  type?: 'text' | 'search' | 'password' | 'email' | 'number';
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  error?: FieldError;
  multiline?: boolean;
  rows?: number;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const TextInput: React.FC<TextInputProps> = ({
  type = 'text',
  name,
  label,
  placeholder,
  defaultValue,
  value,
  required,
  error,
  multiline = false,
  rows = 2,
  size = 'small',
  variant = 'filled',
  onChange,
}) => {
  return (
    <TextField
      type={type}
      name={name}
      label={required ? `${label} *` : label}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      multiline={multiline}
      rows={rows}
      onChange={onChange}
      error={!!error}
      helperText={error ? error.message : null}
      size={size}
      variant={variant}
    />
  );
};
