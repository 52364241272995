import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import { useAppDispatch, useAppSelector } from '../../../store';
import { errorDismissed } from '../../../store/slices/uiSlice';

export const ErrorDialog = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.ui.error);

  const handleClose = () => {
    dispatch(errorDismissed());
  };

  return (
    <Dialog
      open={error !== null}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{error?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {error?.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  );
};
