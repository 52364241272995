import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { FieldError } from 'react-hook-form';

type DatePickerInputProps = {
  label?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  error?: FieldError;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
  onChange: (value: string | undefined) => void;
};

export const DatePickerInput: React.FC<DatePickerInputProps> = ({
  label,
  defaultValue,
  value = '',
  required,
  error,
  size = 'medium',
  variant = 'outlined',
  onChange,
}) => {
  const dateDefaultValue = dayjs(defaultValue) || null;
  const dateValue = dayjs(value) || null;

  return (
    <DatePicker
      label={required ? `${label} *` : label}
      defaultValue={dateDefaultValue}
      value={dateValue}
      onChange={(date: Dayjs | null) => {
        onChange(date?.toString());
      }}
      format='DD.MM.YYYY'
      slotProps={{
        textField: {
          error: !!error,
          helperText: error ? error.message : null,
          size,
          variant,
        },
      }}
    />
  );
};
